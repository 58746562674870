import React, { ReactNode } from 'react'

type contextSectionProps = {
  children: ReactNode
  extraClasses?: string
}

const ContentSection = ({
  children,
  extraClasses,
}: contextSectionProps): JSX.Element => (
  <div id='content' className={`c-section c-section--content ${extraClasses}`}>
    {children}
  </div>
)

export default ContentSection
